.control_button {
    @apply cursor-pointer mx-1;

    & svg {
        @apply fill-blue-400;
    }

 
}

.active svg{
    @apply fill-violet-600;
}