.footer {
    @apply flex w-full justify-center;

    & .footer_wrapper {
        @apply w-5/6 flex justify-between items-center my-4 pt-4 border-t border-gray-200 text-gray-500 px-4;
    }

    & .footer_year {
        @apply flex justify-center ;
    }

    & .footer_copyrights {
        @apply flex w-full justify-end gap-4;
    }

    & .footer_logo {
        @apply w-full ;

        & .footer_icon {
            @apply w-32 ;
        }

    }

}

@media (max-width: 1024px){

    .footer {
        @apply flex w-full  justify-center;
    
        & .footer_wrapper {
            @apply w-10/12  px-1 pt-1 flex-col items-center justify-center gap-2;
        }
    
        & .footer_year {
            @apply text-xs;
        }
    
        & .footer_copyrights {
            @apply flex-col items-center  gap-1 text-xs;

            & .social_link{
                @apply w-32;
            }
        }
    
        & .footer_logo {
            @apply w-8 ;
    
            & .footer_icon {
                @apply w-14 ;
            }
    
        }
    
    }
  }

