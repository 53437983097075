

.words-list {
  @apply pt-10 flex flex-1 flex-wrap gap-4 justify-center relative pb-10;
  
  &-item:nth-child(even){
    & .word-card-image-wrapper img {
      transform: rotate(2deg);
    }
  }

  &-item:nth-child(3){
    & .word-card-image-wrapper img {
      transform: rotate(-2deg);
    }
  }

  .list-done {
    @apply absolute w-20 left-0 -top-16 text-center bg-yellow-300 -rotate-45 font-extrabold text-xl inline-block; 
  }
}


@media (max-width: 1024px){

  .words-list {
  @apply pt-4 pb-1;

  .list-done {
    @apply top-0 -left-2 text-sm w-14; 
  }

  }
}
