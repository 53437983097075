.results_item {
    @apply flex items-center py-1 ;

    & .results_cell {
        @apply px-2;
    }

    & .results_name {
       @apply w-full;
    }

}

.result_odd {
    @apply bg-slate-300/50
}