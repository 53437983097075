
.stats {
    @apply flex w-1/2;
}

.stats_container {
    @apply w-full;
}


@media (max-width: 768px){
    
    .stats {
        @apply flex w-5/6;
    }
}