.streak_line{
    @apply flex justify-center;

    & .streak_item {
        @apply w-12 h-12 mx-2;
    }

    & .streak_done{
        @apply text-green-700;
    }

    & .streak_await{
        @apply text-gray-400;
    }

    & .feature_icon{
        @apply absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white;
    }
}


