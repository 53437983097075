.chart_container {
    @apply w-full flex flex-col items-center;
}
.chart_wrapper {
    @apply w-3/5;
}

@media (max-width: 1024px){
    

        .chart_container {
            @apply w-full flex flex-col items-center;
        }

        .chart_wrapper {
            @apply w-10/12
        }
    

}