.pages-list {
  @apply flex flex-wrap gap-1 px-20;

  li {
    @apply border-b-2 rounded bg-yellow-300 w-7 text-center;
    cursor: pointer;

    a{
      @apply inline-block;
      width: 100%;
    }

    &:hover {
      @apply bg-violet-200;
    }

  }

  .pagelink-done{
    @apply bg-green-300;
  }

  .pagelink-active{
    @apply bg-violet-300;
  }

 
}

@media (max-width: 1024px){
  .pages-list{
    @apply p-2 gap-2;
  }
}
