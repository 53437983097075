@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: 'Open Sans', 'Lato', sans-serif;
  margin: 0;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
}

main {
  padding: 0 3rem;
}

ul,
ol {
  list-style: none;
}

#app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}