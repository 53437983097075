    .stats_card {
        @apply my-6;
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    }


    .stats_header {
        @apply overflow-hidden bg-white shadow sm:rounded-lg;

        & .stats_heading {
            @apply px-4 py-5 sm:px-6 bg-violet-300;

            & .stats_title {
                @apply text-lg font-medium leading-6 text-gray-900;
            }

            & .stats_subtitle {
                @apply mt-1 max-w-2xl text-sm text-gray-700;
            }
        }
    }

    .stats_table {
        @apply border-t border-gray-200;


        & .stats_line {
            @apply px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 flex items-center w-full;
        }

        & .line_odd {
            @apply bg-gray-50;
        }

        & .stats_line_header {
            @apply text-sm font-medium text-gray-500 w-1/2;
        }

        & .stats_line_content {
            @apply w-1/3 text-sm text-gray-900 sm:col-span-2 sm:mt-0;
        }

        & .stats_line_table {
            @apply text-sm text-gray-900 sm:col-span-2 sm:mt-0
        }

        & .stats_list {
            @apply divide-y divide-gray-200 rounded-md border border-gray-200;

            & .stats_list-item {
                @apply flex items-center justify-between py-3 pl-3 pr-4 text-sm;
            }

            & .stats_list-item_title {
                @apply flex ;
            }

            & .stats_list-item_txt {
                @apply flex ml-4;
            }

            & .stats_li_sub {
                @apply ml-2 w-0 flex-1 truncate;
            }


            
        }
    }


    @media (max-width: 768px){
    
        .stats_table {
            & .stats_line {
                @apply px-2 py-5 sm:gap-2 sm:px-3 flex flex-col;
            }

            & .stats_line_header {
                @apply text-sm w-full text-center;
            }

            & .stats_line_content {
                @apply w-4/6 text-center;
            }
    
        }
    
    }