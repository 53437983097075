.game{
    @apply py-4 flex flex-col items-center;

    & .game_start_buttons{
        @apply flex justify-center;
    }

    & .game_body{
        @apply flex flex-col items-center w-full;
    }

    & .game_restart {
        @apply flex my-5;
    }

}