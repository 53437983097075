.social_link{
    @apply h-6 mx-2;

    & .social_a {
        @apply flex gap-2;
    }

    & .social_image {
        @apply h-6 w-6;
    }
}

