.header {
  width: 100%;
  height: 5rem;
  background-color: #feb139;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}

.logo {
  font-family: 'Lato', sans-serif;
  font-size: 2rem;
  color: white;
  margin: 0;
}

.nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
  @apply transition-all;
}

.header li {
  margin: 0 1rem;
}

.header a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.header button {
  font: inherit;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.header a:hover {
  color: #fff80a;
}

.header button:hover {
  background-color: #fff80a;
  color: #38015c;
}


.menu-burger-btn{
  @apply hidden w-10 rounded;

  & svg {
    @apply stroke-white;
  }

  &:hover{
    @apply cursor-pointer shadow-xl;
  }
}


@media (max-width: 1024px){

  .menu-burger-btn{
    @apply inline-block;
  }

  .header-nav {
    @apply hidden z-20 absolute px-6 py-10 rounded-md w-60 transition-all duration-500;
      background-color: #feb139;
      top:4rem;
      right:0;
      transform:translateX(90%);

    &.mobile-nav{
      @apply block;
      transform: none;
      
     & .nav-list{
      @apply flex-col gap-6 justify-center items-center;
      }

    }
  }
  
}