.timer_svg  {
    transform: rotate(-90deg);
    animation: offsettozero linear reverse;
}
  
@keyframes offsettozero {
    to {
      stroke-dashoffset: 0;
    }
}

.timer_svg_text { 
    font: italic 2rem sans-serif;
    transform: rotate(90deg);
}