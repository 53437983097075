.team {
    @apply py-4 flex flex-col items-center;


    & .team_heading {
        @apply text-center;
    }

    & .team_members {
        @apply flex justify-evenly w-full flex-wrap; 
    }
}