.features {
    @apply flex flex-wrap mt-10;

    & .features_item{
     @apply relative w-1/2 lg:w-full px-4 py-4;
    }


    & .feature_icon{
     @apply absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white;
    }
 }