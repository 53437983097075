.button_wrapper{
    @apply inline-flex rounded-md shadow mx-4 min-w-[8rem];

    & .cta_button{
        @apply inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md cursor-pointer w-full;
        box-shadow: 4px 4px 8px 0px rgb(34 60 80 / 20%);
    }

    & .button_primary{
       @apply text-white bg-indigo-600 hover:bg-indigo-700;

       &:hover {
         @apply text-slate-300 bg-indigo-700;
       }
    }

    & .button_secondary{
       @apply text-indigo-600 bg-white hover:bg-indigo-50;
    }
    
    & .button_accept{
       @apply text-white bg-green-600;

       &:hover {
         @apply  text-slate-300 bg-green-500;
       }
    }

    & .button_decline{
       @apply text-white bg-red-600;
       
       &:hover {
         @apply  text-slate-300 bg-red-500;
       }
    }

}

@media (max-width: 576px){
   .button_wrapper{
      @apply mx-2;
   }
}