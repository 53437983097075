.main {
    @apply py-12;

    & .main_hero {
        @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
    }

    & .main_header{
        @apply text-center flex flex-col justify-center items-center;

        & .main_heading {
            @apply mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight;
        }
    
        & .main_subheading {
            @apply mt-4 max-w-2xl text-xl text-gray-500 ;
        }
    }

}
